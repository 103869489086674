import { useInputControl } from '@conform-to/react'
import React, { useId, useRef, useState } from 'react'
import * as SelectPrimitive from "@radix-ui/react-select"
import { NumericFormat } from "react-number-format";
import { Checkbox, type CheckboxProps } from './ui/checkbox.tsx'
import { Input } from './ui/input.tsx'
import { Label } from './ui/label.tsx'
import { Textarea } from './ui/textarea.tsx'
import { Select, SelectContent, SelectTrigger, SelectValue } from './ui/select.tsx'
import { MultiSelect } from './ui/multi-select.tsx'
import { RadioGroup, RadioGroupItem } from "./ui/radio-group.tsx"
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover.tsx'
import { Button } from './ui/button.tsx'
import { cn } from '#app/utils/misc.tsx'
import { CalendarIcon } from 'lucide-react'
import { Calendar } from './ui/calendar.tsx'
import { format } from 'date-fns'

export type ListOfErrors = Array<string | null | undefined> | null | undefined

export function ErrorList({
	id,
	errors,
}: {
	errors?: ListOfErrors
	id?: string
}) {
	const errorsToRender = errors?.filter(Boolean)
	if (!errorsToRender?.length) return null
	return (
		<ul id={id} className="flex flex-col gap-1">
			{errorsToRender.map(e => (
				<li key={e} className="text-[10px] text-foreground-destructive">
					{e}
				</li>
			))}
		</ul>
	)
}

export function SelectField({
	labelProps,
	selectProps,
	triggerProps,
	placeholder,
	children,
	errors,
	className,
}: {
	labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>
	// selectProps: React.SelectHTMLAttributes<HTMLSelectElement>
	selectProps: SelectPrimitive.SelectProps
	triggerProps?: SelectPrimitive.SelectTriggerProps
	placeholder?: string
	children: React.ReactNode
	errors?: ListOfErrors
	className?: string
}) {
	const fallbackId = useId()
	const id = selectProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={className}>
			{labelProps && <Label htmlFor={id} {...labelProps} />}
			<Select
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				{...selectProps}>
				<SelectTrigger {...triggerProps}>
					<SelectValue placeholder={placeholder} />
				</SelectTrigger>
				<SelectContent>
					{children}
				</SelectContent>
			</Select>

			<div className="min-h-[32px] px-4 pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)
}

export function MultiSelectField({
	labelProps,
	selectProps,
	selected,
	onChange,
	options,
	// props,
	errors,
	className,
	...props
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	selectProps: SelectPrimitive.SelectProps
	selected: string[]
	onChange: React.Dispatch<React.SetStateAction<string[]>>
	options: { value: string; label: string }[]
	useAbbreviation?: boolean
	allowCreate?: boolean
	onCreate?: (item: string) => void
	errors?: ListOfErrors
	className?: string

}) {
	const fallbackId = useId()
	const id = selectProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div>
			<Label htmlFor={id} {...labelProps} />
			<MultiSelect
				options={
					options
				}
				selected={selected}
				name={selectProps.name}
				useAbbreviation={props.useAbbreviation}
				allowCreate={props.allowCreate}
				onCreate={props.onCreate}
				onChange={onChange}
				className="w-[560px]"
			/>
			<div className="min-h-[32px] px-4 pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)
}

function flatFieldErrors(flatten?: boolean, errorId?: string, errors?: ListOfErrors) {
	if (flatten) {
		if (errorId) {
			return (
				<div className="min-h-[32px] px-4 pb-3 pt-1">
					<ErrorList id={errorId} errors={errors} />
				</div>
			)
		}
		return
	}

	if (!errors?.length) {
		return null
	}
	return (
		<div className="min-h-[32px] px-4 pb-3 pt-1">
			<ErrorList id={errorId} errors={errors} />
		</div>
	)
}

export function Field({
	labelProps,
	inputProps,
	errors,
	className,
	flatten,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	inputProps: React.InputHTMLAttributes<HTMLInputElement>
	errors?: ListOfErrors
	className?: string
	flatten?: boolean
}) {
	const fallbackId = useId()
	const id = inputProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={className}>
			<Label htmlFor={id} {...labelProps} />
			<Input
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				{...inputProps}
			/>

			{flatFieldErrors(flatten, errorId, errors)}
		</div>
	)
}

export function MoneyField({
	labelProps,
	inputProps,
	errors,
	className,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	inputProps: React.InputHTMLAttributes<HTMLInputElement>
	errors?: ListOfErrors
	className?: string
}) {
	const fallbackId = useId()
	const id = inputProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={className}>
			<Label htmlFor={id} {...labelProps} />
			<NumericFormat
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				prefix="$"
				thousandSeparator
				customInput={Input}
				{...inputProps}
			/>
			<div className="min-h-[32px] px-4 pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)
}

export function TextareaField({
	labelProps,
	textareaProps,
	errors,
	className,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	textareaProps: React.TextareaHTMLAttributes<HTMLTextAreaElement>
	errors?: ListOfErrors
	className?: string
}) {
	const fallbackId = useId()
	const id = textareaProps.id ?? textareaProps.name ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={className}>
			<Label htmlFor={id} {...labelProps} />
			<Textarea
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				{...textareaProps}
			/>
			<div className="min-h-[32px] px-4 pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)
}

export function CheckboxField({
	labelProps,
	buttonProps,
	errors,
	className,
}: {
	labelProps: JSX.IntrinsicElements['label']
	buttonProps: CheckboxProps & {
		name: string
		form: string
		value?: string
	}
	errors?: ListOfErrors
	className?: string
}) {
	const { key, defaultChecked, ...checkboxProps } = buttonProps
	const fallbackId = useId()
	const checkedValue = buttonProps.value ?? 'on'
	const input = useInputControl({
		key,
		name: buttonProps.name,
		formId: buttonProps.form,
		initialValue: defaultChecked ? checkedValue : undefined,
	})
	const id = buttonProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined

	return (
		<div className={className}>
			<div className="flex gap-2">
				<Checkbox
					{...checkboxProps}
					id={id}
					aria-invalid={errorId ? true : undefined}
					aria-describedby={errorId}
					checked={input.value === checkedValue}
					onCheckedChange={state => {
						input.change(state.valueOf() ? checkedValue : '')
						buttonProps.onCheckedChange?.(state)
					}}
					onFocus={event => {
						input.focus()
						buttonProps.onFocus?.(event)
					}}
					onBlur={event => {
						input.blur()
						buttonProps.onBlur?.(event)
					}}
					type="button"
				/>
				<label
					htmlFor={id}
					{...labelProps}
					className="self-center text-body-xs text-muted-foreground"
				/>
			</div>
			<div className="px-4 pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)
}

export function RadioGroupField({
	labelProps,
	radioGroupProps,
	errors,
	className,
	...props
}: {
	labelProps?: JSX.IntrinsicElements['label']
	radioGroupProps: React.ComponentProps<typeof RadioGroup>
	options: { value: string; label: string }[]
	errors?: ListOfErrors
	className?: string
}) {
	const fallbackId = useId()
	const id = radioGroupProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={className}>
			<label htmlFor={id} {...labelProps} />
			<RadioGroup id={id} {...radioGroupProps}>

				{props.options.map(({ value, label }, index) => (
					<div className="flex items-center space-x-2">
						<RadioGroupItem key={value} value={value} id={`option-${id}-${index}`} />
						<Label htmlFor={`option-${id}-${index}`}>{label}</Label>
					</div>
				))}
			</RadioGroup>
			{/* <div className="flex items-center space-x-2">
				<RadioGroupItem value="option-one" id="option-one" />
				<Label htmlFor="option-one">Option One</Label>
			</div>
			<div className="flex items-center space-x-2">
				<RadioGroupItem value="option-two" id="option-two" />
				<Label htmlFor="option-two">Option Two</Label>
			</div> */}
			<div className="px-4 pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)
}

export function CalendarField({
	labelProps,
	inputProps,
	errors,
	className,
	field
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	inputProps: React.InputHTMLAttributes<HTMLInputElement>
	field: any,
	errors?: ListOfErrors
	className?: string
}) {
	const fallbackId = useId();
	const { key, defaultValue, ...checkboxProps } = inputProps
	const [value, setValue] = useState(defaultValue ?? '');
	const input = useInputControl({
		key,
		name: inputProps.name,
		formId: inputProps.form,
		initialValue: defaultValue ? defaultValue : undefined,
	})
	const id = inputProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	let date;
	if (value) {
		date = new Date(value)
	}
	return (
		<div className={className}>
			<Label htmlFor={id} {...labelProps} />
			<div>
				<Popover key={value}>
					<input
						{...inputProps}
						value={value}
						hidden={true}
						onChange={(e) => {
							setValue(e.target.value)
						}}
					/>
					<PopoverTrigger asChild>
						<Button
							variant={"outline"}
							className={cn(
								"w-[240px] pl-3 text-left font-normal",
								!value && "text-muted-foreground"
							)}
						>
							{date ? (
								format(date, "PPP")
							) : (
								<span>Pick a date</span>
							)}
							<CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
						</Button>
					</PopoverTrigger>
					<PopoverContent className="w-auto p-0" align="start">
						<Calendar
							mode="single"
							selected={date}
							captionLayout="dropdown-buttons"
							fromYear={1960} toYear={new Date().getFullYear()}
							onSelect={(value) => {
								if (value instanceof Date) {
									return input.change(value?.toISOString())
								}

								input.change('')

							}}
						/>
					</PopoverContent>
				</Popover>
			</div>
			<div className="min-h-[32px] px-4 pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)
}